import "../packs_common/globalFunctions"

import Translations from "../components/Translations"
import CookieConsentDialog from "../components/CookieConsentDialog"
import { FunctionComponent } from "react"
import ReactOnRails from "../reactOnRails"
import Amex from "../components/amex"

ReactOnRails.register({
  "Translations": Translations as FunctionComponent,
  "CookieConsentDialog": CookieConsentDialog as FunctionComponent,
  "Amex": Amex,
});